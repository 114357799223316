.row-enter {
  opacity: 0;
  transform: scale(0.98);
}
.row-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms, transform 300ms;
}
.row-appear {
  opacity: 0;
  transform: scale(0.98);
}
.row-appear-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 300ms, transform 300ms;
}
.row-exit {
  opacity: 1;
  display: none;
}
.row-exit-active {
  opacity: 0;
  transform: scale(0.98);
  transition: all 300ms, transform 300ms;
}

.keys__key-container .keys__copy {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.keys__key-container:hover .keys__copy {
  opacity: 1;
}

.keys__key {
  position: relative;
}

.notification__icon {
  position: relative;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  display: inline-block;
  background-image: radial-gradient(76% 76% at 64px 32px, #ffffff 4%, #d2d2d2 73%);
  box-shadow: 0 24px 40px 0 rgba(0, 0, 0, 0.08);
}

.notification__icon_color_blue {
  background-image: radial-gradient(76% 76% at 64px 32px, #bee1ff 4%, #57b1ff 72%);
}

.notification__icon_color_green {
  background-image: radial-gradient(76% 76% at 64px 32px, #9eff9e 4%, #73d173 72%);
}

.notification__icon_color_yellow {
  background-image: radial-gradient(76% 76% at 64px 32px, #ffeeb6 4%, #f1c836 72%);
}

.notification__icon_color_red {
  background-image: radial-gradient(76% 76% at 64px 32px, #f7b0b0 4%, #e97373 72%);
}

.notification__icon::after {
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pay::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M37,12 L37,9 C37,8.44771525 36.5522847,8 36,8 L7,8 C6.44771525,8 6,8.44771525 6,9 L6,28 C6,28.5522847 6.44771525,29 7,29 L18,29 L18,33 L4,33 C2.8954305,33 2,32.1045695 2,31 L2,6 C2,4.8954305 2.8954305,4 4,4 L39,4 C40.1045695,4 41,4.8954305 41,6 L41,18.5 L37,16 L10,16 L10,12 L37,12 Z M34,46.5 C26,42.8333333 22,38.1666667 22,32.5 C22,26.8333333 22,23.8333333 22,23.5 C23,23.5 24.3333333,23.5 26,23.5 C28.5,23.5 32.5,20 34,20 C35.5,20 39.5,23.5 42,23.5 C43.6666667,23.5 45,23.5 46,23.5 C46,23.8333333 46,26.8333333 46,32.5 C46,38.1666667 42,42.8333333 34,46.5 Z M34,42.5 C39.3333333,39.9615385 42,36.7307692 42,32.8076923 C42,28.8846154 42,26.9487179 42,27 C41,27 40.1666667,27 39.5,27 C38.5,27 35,24 34,24 C33,24 29.5,27 28.5,27 C27.8333333,27 27,27 26,27 C26,26.9487179 26,28.8846154 26,32.8076923 C26,36.7307692 28.6666667,39.9615385 34,42.5 Z'/%3E%3C/svg%3E");
}

.pay-error::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M24.1421356,21.3137085 L35.4558441,10 L38.2842712,12.8284271 L26.9705627,24.1421356 L38.2842712,35.4558441 L35.4558441,38.2842712 L24.1421356,26.9705627 L12.8284271,38.2842712 L10,35.4558441 L21.3137085,24.1421356 L10,12.8284271 L12.8284271,10 L24.1421356,21.3137085 Z'/%3E%3C/svg%3E");
}

.pay-succesful::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M18.631728,32.8241161 L41.4558441,10 L44.2842712,12.8284271 L18.6794465,38.5146582 L18.6775083,38.5124456 L18.6740559,38.5165264 L5,24.8492424 L7.82842712,22.0208153 L18.631728,32.8241161 Z'/%3E%3C/svg%3E");
}

.stop::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M24,2 C36.1502645,2 46,11.8497355 46,24 C46,36.1502645 36.1502645,46 24,46 C11.8497355,46 2,36.1502645 2,24 C2,11.8497355 11.8497355,2 24,2 Z M24,7 C14.6111593,7 7,14.6111593 7,24 C7,33.3888407 14.6111593,41 24,41 C33.3888407,41 41,33.3888407 41,24 C41,14.6111593 33.3888407,7 24,7 Z M24.5,29 C25.6045695,29 26.5,29.8954305 26.5,31 L26.5,32 C26.5,33.1045695 25.6045695,34 24.5,34 L23.5,34 C22.3954305,34 21.5,33.1045695 21.5,32 L21.5,31 C21.5,29.8954305 22.3954305,29 23.5,29 L24.5,29 Z M25,14 C25.5522847,14 26,14.4477153 26,15 L26,25 C26,25.5522847 25.5522847,26 25,26 L23,26 C22.4477153,26 22,25.5522847 22,25 L22,15 C22,14.4477153 22.4477153,14 23,14 L25,14 Z'/%3E%3C/svg%3E");
}

.start::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M45,6 C45.5522847,6 46,6.44771525 46,7 L46,41 C46,41.5522847 45.5522847,42 45,42 L3,42 C2.44771525,42 2,41.5522847 2,41 L2,7 C2,6.44771525 2.44771525,6 3,6 L45,6 Z M41,11 L7,11 L7,37 L41,37 L41,11 Z M19,17 L31,24 L19,31 L19,17 Z'/%3E%3C/svg%3E");
}

.upgrade::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cpath fill='%23FFF' fill-rule='evenodd' d='M32,40 L32,43 L16,43 L16,40 L32,40 Z M32,34 L32,37 L16,37 L16,34 L32,34 Z M24,5 L40,23 L32,23 L32,31 L16,31 L16,23 L8,23 L24,5 Z'/%3E%3C/svg%3E");
}

.limits-form__error-message {
  margin: 8px 8px;
}

.limits-form__title-message {
  margin: 16px 0 8px 0;
}

.limits-form__container-message {
  min-height: 64px;
}

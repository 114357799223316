.steps-enter,
.steps-appear {
  opacity: 0;
}
.steps-enter-active,
.steps-appear-active {
  opacity: 1;
  transition: opacity 350ms;
}

.steps-exit {
  opacity: 1;
}
.steps-exit-active {
  opacity: 0;
  transition: opacity 350ms;
}
